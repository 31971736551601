.homepage {
  .page-main {
    @include respond-to(1280px) {
      // background: url('../images/dys-interior.jpg');
      // background-position: 0 0;
      // background-size: cover;
    }
    // &:before {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 1;
    //   display: block;
    //   width: 100%;
    //   height: 280px;
    //   content: '';
    //   @include linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    //   @include respond-to(1024px) {
    //     width: calc(100% - 426px);
    //     height: 239px;
    //     @include linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    //   }
    // }
    // &:after {
    //   display: none;
    //   @include respond-to(1024px) {
    //     position: absolute;
    //     bottom: 11px;
    //     left: 0;
    //     z-index: 1;
    //     display: block;
    //     width: calc(100% - 426px);
    //     height: 239px;
    //     content: '';
    //     @include linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    //   }
    // }
  }

  .content {
    position: relative;
    margin: 0 auto;
    padding: 68px 0 0;
    min-height: 100%;
    max-width: 400px;

    @include respond-to(1280px) {
      padding: 0 120px 0 60px;
      max-width: 100%;
      width: 100%;
    }
  }
}

.authenticated.homepage .page-main:before {
  top: 53px;
}

.homepage-blurb {
  position: absolute;
  top: 48%;
  margin-left: 60px;

// position: absolute;
  // top: 35%;
  // left: 3.75rem;
  // display: block;
  // margin: 0 auto;
  // width: 54%;
  width: calc(100% - 546px);
  transform: translateY(-50%);

  @media only screen and (max-width:1023px) {
    position: relative;
    top: 50%;
    left: 0;
    margin: 0 auto;
    padding: 0 30px 0 0;
    width: 100%;
    transform: none;
  }

  // @media only screen and (max-width: 768px) {
  //   top: 70% !important;
  // }

  // @media only screen and (max-width: 414px) {
  //   top: 65% !important;
  // }

  // @media only screen and (max-width: 375px) {
  //   top: 80% !important;
  // }

  // @media only screen and (max-width: 320px) {
  //   // position: relative;
  //   // padding: 0;
  //   top: 67%;
  //   margin: 0 auto;
  // }

  h2 {
    margin-bottom: 15px;
    color: #ffffff;
    text-transform: uppercase;
    text-shadow: 6px 2px 15px rgba(0, 0, 0, 0.5);
    font-weight: 700;
    font-style: normal;
    font-size: 64px;
    line-height: 57px;
    font-family: $secondary-font;

    @media only screen and (max-width: 640px) {
      font-size: 3.0rem;
      line-height: 40px;
    }
  }

  p {
    color: #bfbfbf;
    font-weight: 400;
    font-size: 1.125rem;
    font-family: $primary-font;
    line-height: 1.8725rem;
  }
}

.homepage-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  margin: 0 auto;
  width: 100%;
  height: auto;
  // .homepage-overlay {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   display: block;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 13, 26, 0.85);
  // }

  img.background-image {
    // position: absolute;
    min-height: 100vh;
    width: 100%;
    width: 100vw;
    object-fit: cover;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      width: 100%;
      height: auto;
    }

    @media only screen and (max-width: 1280px) {
      width: auto;
    }
  }
}
