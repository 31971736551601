.popup {
  &.no {
    display: none;
  }

  #ouibounce-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: none;
    width: 100%;
    height: 100%;
    font-family: $secondary-font, sans-serif;

    a {
      color: #ffd200;
      text-decoration: none;
      transition: color 0.2s ease;

      &:hover {
        color: #5798d9;
      }
    }

    .underlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 13, 26, 0.93);
      cursor: pointer;
      -webkit-animation: fadein 0.5s;
              animation: fadein 0.5s;
    }

    .modal {
      position: relative;
      top: 119px;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: block;
      margin: auto;
      max-width: 100%;
      width: 855px;
      height: auto;
      border: 1px solid #666666;
      background: #404040;
      -webkit-animation: popin 0.3s;
              animation: popin 0.3s;

      @media only screen and (max-width: 860px) {
        top: 0;
        overflow-y: scroll;
        height: 100%;
      }
    }

    // .modal-title {
    //   margin: 0;
    //   padding: 10px;
    //   border-radius: 4px 4px 0 0;
    //   background-color: #252525;
    //   color: #ffffff;
    //   text-align: center;
    //   font-size: 18px;
    // }

    .modal-body {
      padding: 35px 60px 0;

      .special,
      .special-details {
        margin-bottom: 30px;
        border-bottom: 1px solid #666666;
      }

      .disclaimer {
        margin-bottom: 30px;

        p {
          font-style: italic;
          font-size: 14px;
        }
      }

      .schedule-appointment,
      .got-it {
        .schedule-button {
          display: inline-block;
          margin-bottom: 30px;
          padding: 8px 40px;
          background: #ffd200;
          color: #403400;
          text-decoration: none;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 17px;
          font-family: $secondary-font;

          &:hover {
            background: #5798d9;
            color: #1a2d40;
          }
        }

        .got-it-button {
          display: inline-block;
          padding-bottom: 30px;
          font-size: 14px;
          font-family: nimbus-sans, sans-serif;
        }
      }

      h2 {
        margin: 0.2em 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 48px;
      }

      h3 {
        margin: 0.2em 0;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 100;
        font-size: 24px;
      }

      .modal-close {
        position: absolute;
        top: 5px;
        right: 20px;
        color: black;
        text-decoration: none;
        font-weight: bold;
        font-size: 30px;
        cursor: pointer;
      }

      p {
        padding: 0 0 15px 0;
        color: #bfbfbf;
        font-weight: 500;
        font-style: normal;
        font-size: 18px;
        font-family: nimbus-sans, sans-serif;
      }
    }

    .modal-footer {
      position: absolute;
      top: -32px;
      left: -32px;
      padding: 0;
      width: 32px;
      height: 32px;
      border: 1px solid #666666;
      color: #ffffff;
      text-decoration: none;
      font-weight: bold;
      font-size: 30px;
      cursor: pointer;

      // p {
      //   position: absolute;
      //   top: -12px;
      //   left: 8px;
      //   color: #ffffff;
      //   font-size: 18px;
      //   cursor: pointer;
      // }

      span {
        position: absolute;
        top: 6px;
        left: 8px;
        color: #ffffff;
        font-size: 18px;
        cursor: pointer;
      }

      @media only screen and (max-width: 1023px) {
        top: 0;
        right: 0;
        left: auto;
        border: none;
      }
    }

    @-webkit-keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @-ms-keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes popin {
      0% {
        opacity: 0;
        -webkit-transform: scale(0);
                transform: scale(0);
      }

      85% {
        opacity: 1;
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
      }

      100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
      }
    }

    @-ms-keyframes popin {
      0% {
        opacity: 0;
        -ms-transform: scale(0);
            transform: scale(0);
      }

      85% {
        opacity: 1;
        -ms-transform: scale(1.05);
            transform: scale(1.05);
      }

      100% {
        opacity: 1;
        -ms-transform: scale(1);
            transform: scale(1);
      }
    }

    @keyframes popin {
      0% {
        opacity: 0;
        -webkit-transform: scale(0);
            -ms-transform: scale(0);
                transform: scale(0);
      }

      85% {
        opacity: 1;
        -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
                transform: scale(1.05);
      }

      100% {
        opacity: 1;
        -webkit-transform: scale(1);
            -ms-transform: scale(1);
                transform: scale(1);
      }
    }
  }

  #ouibounce-modal.show {
    display: block;
  }
}


