.search-box-container {
  position: absolute;
  z-index: 99999;
  display: none;
  padding: 30px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 13, 26, 0.9);

  .search-box {
    position: relative;
    top: 35%;
    display: block;
    padding: 0;

    @media only screen and (max-width: 640px) {
      top: 25%;
    }
  }

  .search-close {
    display: block;
    padding-bottom: 30px;
    color: #ffffff;
    font-size: 1.25rem;
    cursor: pointer;
  }

  label.search-box-heading {
    display: block;
    padding-left: 50px;
    color: #ffd200;
    text-transform: uppercase;
    font-size: 20px;
    font-family: $secondary-font;

    @media only screen and (max-width: 640px) {
      padding-left: 41px;
    }
  }

  input {
    padding: 0 48px;
    width: 100%;
    border: none;
    background: transparent;
    color: #ffffff;
    text-transform: uppercase;
    text-shadow: 6px 2px 15px rgba(0, 0, 0, 0.5);
    font-weight: 900;
    font-style: normal;
    font-size: 7.25rem;
    font-family: $secondary-font;

    @media only screen and (max-width: 640px) {
      padding: 0 40px;
      font-size: 3.0rem;
    }
  }

  input::placeholder {
    color: #ffffff;
  }

  input:focus::-webkit-input-placeholder {
    color: transparent;
    text-shadow: none;
  }

  input:focus:-moz-placeholder {
    color: transparent;
    text-shadow: none;
  }

  /* FF 4-18 */
  input:focus::-moz-placeholder {
    color: transparent;
    text-shadow: none;
  }

  /* FF 19+ */
  input:focus:-ms-input-placeholder {
    color: transparent;
    text-shadow: none;
  }

  /* IE 10+ */
  button {
    border: none;
    background: transparent;
    cursor: pointer;

    span.search {
      position: absolute;
      top: 32px;
      right: 3px;
      padding-right: 5px;

      &:before {
        position: relative;
        top: 4px;
        margin-right: 10px;
        content: '\f002';
        font-size: 22px;
        font-family: FontAwesome;
      }
    }
  }
}
// button.search::before {
//   position: relative;
//   top: -2px;
//   left: 0;
//   margin-right: 10px;
//   content: '\f002';
//   font-size: 22px;
//   font-family: FontAwesome;
// }

