/*!
* DYS - 2015 DNN Site Skin
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets
@import 'presets/grid-settings';
@import 'presets/variables';
// @import 'presets/mixins';
@import 'presets/mixins-new';
@import 'presets/helper-classes';
 

// Vendor
// @import 'vendor/bourbon/4.2.4/bourbon';
@import 'vendor/normalize/3.0.2/normalize';
@import 'vendor/article-columns';
@import 'vendor/article-grid';
@import 'vendor/columns';
@import 'vendor/cta';
@import 'vendor/images';
@import 'vendor/modal';
@import 'vendor/tables';
@import 'vendor/accordions';
@import 'vendor/tabs';

// Layout
@import 'layout/flexbox';

// Components
@import 'components/general';
@import 'components/page';
@import 'components/page-main';
@import 'components/page-nav';
@import 'components/logo';
@import 'components/touch-menu';
@import 'components/nav-global';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/page-footer';
@import 'components/dealers';
@import 'components/forms';
@import 'components/typography';
@import 'components/search';
@import 'components/employment';
@import 'components/tables';
@import 'components/tabs';
@import 'components/accordions';
@import 'components/accordions';
@import 'components/parallax';
@import 'components/accessibility';
@import 'components/ouibounce';
@import 'components/dockmap';
@import 'components/_inventory';
@import 'components/cta';
@import 'components/highlighted-text';
@import 'components/image-lists';
@import 'components/buttons';
@import 'components/alerts';
@import 'components/modal';



// Pages
@import 'components/homepage';
@import 'components/subpage';

// Font Awesome
@import 'vendor/font-awesome/4.4.0/font-awesome';

// Owl Carousel - js carousel for homepage vendors
@import 'vendor/owlcarousel/2.0.0/owl.carousel';

// Extras
@import 'misc';
@import 'dnn';
@import 'shame';
