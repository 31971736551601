.modal {
  .close-modal {
    top: 10px;
    right: 10px;
    background-color: transparent;
  
    &::before {
      content: '\f00d';
      font-family: FontAwesome;
      font-weight: 900;
      font-size: 22px;
      color: #737373;
      opacity: .5;
    }
  
    &:hover,
    &:focus {
      background-color: transparent;

      &::before {
        opacity: 1;
      }
    }
  }
}