table[data-arx-type='table'] {
  th {
    color: #353535;
    background: #d5d5d5;
    border-right: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }

  td,
  th {
    padding: 8PX !important;
    font-size: 14PX !important;
  }
}

table.responsive-table {
  .cell-heading {
    display: none;
  }
}

@include mq(0 $medium) {
  table.responsive-table {
    width: 100%;

    thead tr {
      display: none;
    }

    td {
      display: block;
      clear: left;
    }

    .cell-heading {
      display: block;
    }

    .cell-content {
      display: block;
    }
  }
}