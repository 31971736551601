a {
  &.btn {
    color: white;
    display: inline-block;
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    text-decoration: none;
    text-align: center;
    line-height: initial;
    transition: all .33s ease-in-out;

    &:hover,
    &:focus {
      text-decoration: none;
      color: white;
    }

    &:visited {
      color: white;
    }

    &.btn-primary {
      color: #262626;
      background: $gold;
      border: 2px solid $gold;

      @at-root .subpage .content {
        a.btn.btn-primary {
          color: #262626;
        }
      }

      &:visited {
        color:#262626;;
        background: $gold;
        border-color: $gold;
      }

      &:hover,
      &:focus {
        color: #262626;
        background: #5798d9;
        text-decoration: none;
        border-color: #5798d9;
      }
    }

    &.btn-secondary {
      color: #262626;
      background: #5798d9;
      border: 2px solid #5798d9;

      @at-root .subpage .content {
        a.btn.btn-secondary {
          color: #262626;

          &:hover,
          &:focus {
            color: white;
          }
        }
      }

      &:visited {
        color: #262626;;
        background: #5798d9;;
        border-color: #5798d9;;
      }

      &:hover,
      &:focus {
        color: white;
        background:  black;
        text-decoration: none;
        border-color: black;
      }
      
    }
  }
}


