.logo {
  position: relative;
  top: -10px;
  z-index: 16;
  display: block;
  width: 260px;
  height: 65px;
  background-image: url('../images/dys-logo.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  text-indent: -10000rem;
  color: $white;

  @include respond-to(1280px) {
    position: absolute;
    top: 60px;
    left: 60px;
    width: 270px;
    height: 67px;
  }
}
