.dealers {
  display: none;

  @include respond-to(1280px) {
    position: absolute;
    bottom: 49px;
    left: 0;
    z-index: 10;
    display: block;
    margin-left: 60px;
    width: calc(100% - 546px);
  }

  .btn-next,
  .btn-prev {
    position: absolute;
    top: 50%;
    left: -60px;
    display: block;
    float: left;
    padding: 0  20px;
    width: 60px;
    height: 60px;
    color: $pearl;
    font-size: 30px ;
    transform: translateY(-50%);

    i {
      display: inline-block;
      margin-top: 13px;
      text-decoration: none;
      opacity: 0.35;
      transition: opacity 0.2s ease;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }

  .btn-next {
    border-left: 1px solid rgba($white, 0.25);
    text-align: left;
  }

  .btn-prev {
    border-right: 1px solid rgba($white, 0.25);
    text-align: right;
  }

  .carousel {
    float: left;
    width: calc(100% - 120px);
    height: 60px;

    .owl-item {
      backface-visibility: initial;
    }

    .item {
      img {
        opacity: 0.5;
        transition: opacity 0.2s ease;
      }

      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }

  img {
    margin: 0 20px;
  }

  a.cycle-slide {
    margin: 0 20px;
    max-width: 175px;
    opacity: 0.5 !important;
  
    &:hover {
      opacity: 1 !important;
    }
  }
}


