.tabs {
  .tab-list {
    text-transform: none;

    @at-root .subpage .content {
      .tab-list {
        border-top: none;
      }
    }

    li {
      @at-root .subpage .content {
        .tab-list li {
          margin-left: 0;
          padding: 0;
          border-bottom: none;
          @include fontsize(15 17);
        }
      }

      a {
        padding: 8px 12px 6px;
        color: #bfbfbf;
        text-decoration: none;
        font-weight: 700;
        background: rgba(235, 235, 235, 0.2);

        &:hover,
        &[role='tab']:focus {
          color: white;
          background:  rgba(153, 172, 191, 0.4);
        }

        &[aria-selected] {
          padding: 8px 12px 6px;
          color: white;
          background: rgba(113, 131, 178, 0.3);

          &:hover,
          &[role='tab']:focus {
            color: white;
            background:  rgba(113, 131, 178, 0.3);;
          }
        }
      }
    }
  }

  .tab-content {
    padding: 6px 12px;
    border-top: 1px solid rgba(235, 235, 235, 0.1);
    border-bottom: 1px solid rgba(235, 235, 235, 0.1);
    background: rgba(235, 235, 235, 0.1);
    font-size: 15px;
  }
}