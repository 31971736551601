// Sizes
$short: 480px;
$tall: 640px;
$grande: 768px;
$venti: 1280px;
$trenta: 1440px;
$supremo: 1920px;
$maximo: 2040px;

// Fonts

$primary-font: 'Archivo', sans-serif;
$secondary-font: 'Fira Sans Extra Condensed', sans-serif;

$font-awesome: 'FontAwesome';

// Shadows

// Grayscale
$white: #ffffff;
$pearl: #fbfbfb;
$alabaster: #fafafa;
$concrete: #f2f2f2;
$gallery: #ebebeb;
$aluminum: #cccccc;
$silver: #bfbfbf;
$scorpion: #595959;
$tundora2: #424242;
$tundora: #404040;
$black: #000000;

// Yellow
$gold: #ffd200;

// Blue
$nepal: #99acbf;
$hoki: #5C7B93;
$wild-blue-yonder: #7183b2;
$havelock-blue: #5798d9;
$limed-spruce: #3c464f;
$ebony-clay: #26313c;
$big-stone: #1a2d40;
$firefly: #09141f;
$black-pearl: #03101c;
$flying-dragon: #030d19;
$jolly-roger: #03080e;
$prussian-blue: #002d59;
$blue-charcoal: #000d1a;

// Other
$facebook-blue: #003e7e;

//Tables
$table-border: #0b1621;
$table-head-text: white;
$table-head-background: rgba(113, 131, 178, 0.3);
$table-head-border: #0b1621;
