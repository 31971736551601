.subpage.sub-parallax {
  // @media only screen and (max-width: 1280px) {
  //   background: url('/Themes/DYS/assets/dist/images/dys-interior.jpg') no-repeat top fixed;
  //   background-color: #000d1a;
  // }

  &:after {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    background-color: rgba(0, 13, 26, 0.85);
    background-size: cover;
    content: '';
  }

  .page-main {
    position: relative;

    @media only screen and (max-width: 1279px) {
      top: -16px;
      padding-top: 46px;
    }

    .page-nav {
      top: 0;
    }
  }

  .content {
    position: relative;
    margin: 0 auto;
    padding: 0;
    max-width: calc(100vw - 80px);
    color: $silver;
    font-weight: 400;
    font-size: 16px;
    font-family: $primary-font;

@include respond-to(1280px) {
      max-width: 100%;
      width: 100%;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }

    @media only screen and (max-width: 1279px) {
      padding: 68px 0;
    }

    a {
      color: $gold;
      text-decoration: underline;
      transition: color 0.2s ease;

      &:hover {
        color: $havelock-blue;
      }
    }

    h1:first-child {
      position: absolute;
      z-index: 1;
      margin-top: 180px;
      margin-left: 60px;

      @media only screen and (max-width: 1279px) {
        position: relative;
        margin-top: 0 !important;
        margin-left: 0;
      }
    }

    p.anchor,
    h3.anchor {
      text-align: right;
      text-transform: uppercase;
      margin-bottom: 0;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 20px;
      font-family: $secondary-font;

      @media only screen and (max-width: 1279px) {
        display: none;
      }

      a {
        text-decoration: none;

        &:before {
          margin-right: 7px;
          color: #ffd200;
          content: '\f0ab';
          font-size: 18px;
          font-family: FontAwesome;
          transition: color 0.2s ease;
        }

        &:hover:before {
          color: #5798d9;
        }
      }
    }
  }

  .photo {
    position: relative;
    padding-bottom: 1%;

// min-height: 100vh;
    height: 100%;
    background-attachment: fixed;
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1279px) {
      min-height: 0 !important;
      background-image: none !important;
    }

    &:last-child {
      // min-height: 100vh;
      min-height: 55vh;
    }

    p {
      font-size: inherit;
    }
  }

  .photo-content {
    margin-bottom: 15vw;
    padding: 1.875rem 30rem 3.75rem 3.75rem;
    background: rgba(0, 13, 26, 0.8);

    // &:first-of-type {
    //   padding-top: 150px !important;
    // }

    @media only screen and (max-width: 1279px) {
      position: relative !important;
      margin-bottom: 0 !important;
      padding: 0 !important;
      background: transparent;
    }

    @media only screen and (max-height: 768px) {
      margin-bottom: 30vw;
    }
  }
}

.subpage {
  .content {
    position: relative;
    margin: 0 auto;
    padding: 68px 0;
    max-width: calc(100vw - 80px);
    color: $silver;
    font-weight: 400;
    font-size: 16px;
    font-family: $primary-font;

    @include respond-to(1280px) {
      padding: 180px 480px 180px 60px;
      max-width: 100%;
      width: 100%;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }

    a {
      color: $gold;
      text-decoration: none;
      transition: color 0.2s ease;

      &:hover {
        color: $havelock-blue;
      }
    }
  }
}

.contact,
.multi-list,
.multi-column {
  display: block;
  margin-left: -15px;
  width: 100%;

@include respond-to(1280px) {
    display: inline-flex;

    p:nth-child(even) {
      border-right: none;
      border-left: none;
    }
  }

  .location,
  .lists,
  .column {
    padding: 10px 15px;
    width: 100%;

    h2,
    h3 {
      margin-top: 0;
      padding-bottom: 7px;
    }

    img {
      width: 100%;
    }

    ul.location-details,
    ul.list-details {
      margin: 0;
      width: 100%;
      border-top: 3px solid #2d3c56;
      list-style: none;

      li {
        margin-left: -40px;
        padding: 7px 0;
        border-bottom: 1px solid #333d47;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        font-family: $primary-font;
        line-height: 22px;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    span {
      color: #ffffff;
      font-weight: 600;
    }
  }
}

.subpage-background {
  position: fixed;
  top: 0;

  &:after {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    background-color: rgba(0, 13, 26, 0.85);
    background-size: cover;
    content: '';
  }

  img {
    position: fixed;
    left: 0;
    z-index: -1;
    height: 100vh;

    // @media only screen and (max-height: 900px) {
    //   width: 100%;
    //   height: auto;
    // }

    // @media only screen and (max-width: 414px) {
    //   width: auto;
    //   height: 100vh;
    // }
  }
}

.spacer {
  margin: 30px 0;
  width: 100%;
  height: 2px;
  background: #333d47;
  opacity: 0.75;
}
