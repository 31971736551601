.employment-post {
  position: relative;
  margin-top: 15px;
  padding-bottom: 10px;
  padding-left: 120px;

  .job-number {
    position: absolute;
    top: 0;
    left: 0;
  }

  .job-title a {
    color: inherit;
  }
}
