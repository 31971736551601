.nav-primary {
  position: absolute;
  bottom: 100px;
  color: $tundora;

  .ul1 {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .li1 {
    position: relative;
    margin: 0;
    padding: 0;

    &:hover,
    &:focus {
      .a1 {
        color: $hoki;
      }
    }
  }

  .a1 {
    position: relative;
    display: block;
    padding-right: 18px;
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 28px;
    line-height: 25px;
    font-family: $secondary-font;
  }

  .ul2 {
    display: none;
  }

  .a2 {
    display: block;

// line-height: 25px;
    color: $nepal;
    text-decoration: none;
    font-size: 15px;
    font-family: $primary-font;

    &:hover {
      color: $white;
    }
  }
}

.page-nav {
  .nav-primary {
    padding: 45px 0;

    .li1:hover {
      .ul2 {
        display: block;
      }
    }

    .a1 {
      position: relative;
      padding: 3px 30px;
    }

    .ul2 {
      position: absolute;
      top: -12px;
      left: -284px;
      display: none;
      margin: 0;
      padding: 20px 30px 20px 19px;
      width: 284px;

// background: rgba($blue-charcoal, 0.93px;
      list-style: none;

      &:after {
        position: absolute;
        top: 12px;
        right: 0;
        display: block;
        width: 5px;
        height: 30px;
        background: $white;
        content: '';
      }

      &:before {
        position: fixed;
        top: 0;
        right: 426px;
        display: block;
        width: 280px;
        height: 100%;
        background: rgba(0, 13, 26, 0.93);
        content: '';
      }
    }

    .li2 {
      position: relative;
      display: block;
      padding: 5px 0;
    }

    .a2 {
      position: relative;
      display: block;
      margin: 0;
      padding: 0 0 0 12px;
      font-size: 15px;
      line-height: 15px;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 2px;
        height: 100%;
        background: $havelock-blue;
        content: '';
      }

      &:hover {
        color: $white;

        &:before {
          display: block;
        }
      }
    }
  }
}

.touch-menu {
  .nav-primary {
    position: relative;
    bottom: 0;
    padding: 32px 30px;

    .li1 {
      &.parent {
        .a1:after {
          position: absolute;
          top: 8px;
          right: 0;
          display: block;
          width: 24px;
          height: 19px;
          border-left: 1px solid $silver;
          background-image: url('../images/plus.png');
          background-position: 100% 50%;
          background-repeat: no-repeat;
          content: '';
        }
      }

      &.open {
        .a1:after {
          background-image: url('../images/minus.png');
        }

        .ul2 {
          display: block;
        }
      }
    }

    .a1 {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
