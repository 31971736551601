.touch-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 19;
  display: block;
  min-width: 320px;
  width: 100%;
  background: $white;

  @include respond-to(1280px) {
    display: none;
  }
  // &:before {
  //   position: absolute;
  //   bottom: 10px;
  //   left: 0;
  //   z-index: 2;
  //   display: block;
  //   width: 100%;
  //   height: 1px;
  //   background: $silver;
  //   content: '';
  // }
  // &:after {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 1;
  //   display: block;
  //   width: 100%;
  //   height: 10px;
  //   background: $prussian-blue;
  //   content: '';
  // }

  &.open {
    // position: absolute;
    // top: 0;
    // bottom: auto;
    // min-height: calc(100%);
    position: fixed;
    top: 0;
    bottom: 0;
    overflow: auto;
    height: 100%;

    .touch-menu-toggle {
      display: none;
    }

    .touch-menu-panel {
      display: block;
    }
  }

  .touch-menu-toggle {
    position: absolute;
    bottom: 0;
    padding: 14px 0 25px;
    width: 100%;
    background: $white;
    color: $tundora;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 20px;
    font-family: $secondary-font;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, .25);

    &:before {
      display: inline;
      margin-top: 1px;
      margin-right: 6px;
      width: 10px;
      content: '\f0c9';
      font-family: $font-awesome;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .touch-menu-panel {
    display: none;
  }

  .touch-menu-header {
    padding: 14px 30px 17px;
  }

  .touch-menu-close {
    position: relative;
    float: left;
    padding-left: 18px;
    color: $tundora;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 22px;
    font-family: $secondary-font;

    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      content: '\f00d';
      font-size: 18px;
      font-family: $font-awesome;
    }
  }
}

.authenticated .touch-menu.open {
  // top: 53px;
  min-height: calc(100% - 53px);
}
