.image-list {
  @at-root .subpage .content {
    .image-list .content {
      padding: 0;
    }
  }

  .content {
    width: 100%;
    
    @include medium-up {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 230px);
      align-content: center;
    }

    > * {
      width: 100%;
    }
  }

  .image {
    margin: 0 20px 15px 0;;
  }
}

.image-list[data-arx-type='layer'] {
  position: relative;
  margin-bottom: 15px;
  outline: 2px dashed rgba(0, 88, 251, 0.2);

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    display: inline-block;
    padding: 2px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    background: rgba(255,255,255,0.5);
    border: 1px dashed rgba(0, 88, 251, 0.2);
    border-top: 0;
    border-right: 0;
    content: 'List With Images';
  }
}