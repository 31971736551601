.parallax {
  margin: 30px -80px;
  padding-bottom: 40px;
  height: 569px;
  background: url('/Themes/DYS/assets/dist/images/parallax.png');
  background-attachment: fixed;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;

  @media only screen and (max-width: 1279px) {
    display: none;
  }
}
