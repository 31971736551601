.alerts {
  position: relative !important;
  background: $gold !important;
  width: 100%;
  z-index: 15;

  @include respond-to(1280px) {
    // position: fixed !important;
    top: 0;
    left: 0;
  }

  &.multiple {
    .alert {
      padding: 10px 15px 40px 15px !important;

      @include respond-to(768px) {
        padding: 12px 40px 16px 75px !important;
      }
    }
  }

  .alert {
    color: #404040 !important;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    max-width: none;

    @include respond-to(768px) {
      padding: 12px 40px 16px !important;
    }

    &:hover,
    &:focus {
      color: black !important;
      text-decoration: none !important;
    }

    .alert-text {
      font-family: $primary-font;
    }
  }

  .alert-nav {
    top: auto !important;
    bottom: 10px;
    transform: translateX(-50%) !important;
    left: 50% !important;

    @include respond-to(768px) {
      top: 50% !important;
      bottom: auto !important;
      transform: translateY(-50%) !important;
      left: 0 !important;
    }

    .alert-prev,
    .alert-next {
      color: #404040 !important;

      &:hover,
      &:focus {
        color: black !important;
      }

    }
  }

  .icon {
    font-size: 20px !important;
    position: relative;
    top: 1px;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #404040 !important;
    z-index: 999;
  }
}