.highlighted-text {
  position: relative;
  margin: 25px auto;
  background: white;
  padding: 25px 55px; 
  border-radius: 10px;

  @include medium-up {
    padding: 25px 40px 25px;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 25px;
    height: 100%;
    background: #003d81;
    top: 0;
    left: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @include medium-up {
      width: 25px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include fontsize(24 30);
    color: #262626;
  }

  p,
  ul,
  ol {
    @include fontsize(16 28);
    color: #262626;

    @include medium-up {
      @include fontsize(18 30);
    }

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.highlighted-text[data-arx-type='layer'] {
  position: relative;
  margin-bottom: 15px;
  outline: 2px dashed rgba(0, 88, 251, 0.2);

  p {
    color: black;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    display: inline-block;
    padding: 2px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    background: rgba(255,255,255,0.5);
    border: 1px dashed rgba(0, 88, 251, 0.2);
    border-top: 0;
    border-right: 0;
    content: 'Highlighted Text';
  }
}