.accordions {
  .accordion-title {
    @at-root .subpage .content {
      .accordion-title {
        margin-bottom: 0;
      }
    }
    button {
      @include fontsize(15 18);
      letter-spacing: 1px;
      margin: 0;
      padding: 15px 45px 5px 15px;
      min-height: 35px;
      color: #bfbfbf;
      background: rgba(235, 235, 235, 0.2);;
      border-bottom: 3px solid #0b1622;

      &:after {
        position: absolute;
        top: 50%;
        right: 15px;
        font-family: FontAwesome;
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        color: #bfbfbf;
        text-align: center;
        text-decoration: inherit;
        content: '\f067';
        transform: translateY(-8px);
        border-left: 1px solid rgba(191, 191, 191, 0.3);
        padding-left: 15px;
      }

      &:hover,
      &:focus {
        border-bottom: 3px solid #0b1622;
        background: rgba(153, 172, 191, 0.4);
        color: white;

        &:after {
          color: white;
          border-left: 1px solid #bfbfbf;
        }
      }
    }

    &.is-active button,
    &.is-active button:focus {
      color: white;
      background: rgba(113, 131, 178, 0.3);
      border-bottom: 3px solid #0b1622;

      &:after {
        content: '\f068';
        color: #bfbfbf;
      }
    }
  }

  .accordion-content {
    margin-bottom: 5px;
    padding: 20px;
    border-top: 1px solid rgba(235, 235, 235, 0.1);
    border-bottom: 1px solid rgba(235, 235, 235, 0.1);
    background: rgba(235, 235, 235, 0.1);

    p {
      margin: 0;
      font-weight: 400;
      @include fontsize(15 20);
      font-family: $primary-font;
    }
  }
}

