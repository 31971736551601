html,
body,
form {
  min-height: 100%;
  height: 100%; // required for full height flexbox.

@include respond-to(1280px) {
  }
}

.page {
  @include respond-to(1280px) {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .page-inner {
    flex: 1;
  }
}

.page-inner {
  min-height: 580px;

  @include respond-to(1280px) {
    display: flex;
    flex-direction: row;
  }

  .page-main {
    @include respond-to(1280px) {
      flex: 1;
    }
  }

  .page-nav {
    @include respond-to(1280px) {
      flex: none;
    }
  }
}

.page-nav {
  display: none;

@include respond-to(1280px) {
    position: fixed;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;

    @at-root body.has-alert {
      .page-nav {
        position: absolute;
      }
    }
  }

  .nav-global,
  .nav-primary,
  .page-footer {
    @include respond-to(1280px) {
      flex: none;
    }
  }

  .stretch {
    @include respond-to(1280px) {
      flex: 1;
    }
  }
}

.homepage .page-main {
  @include respond-to(1280px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

body.authenticated {
  min-height: calc(100% - 53px);
  height: calc(100% - 53px);
}
