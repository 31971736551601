.cta-card {
  background: #003d81;
  // border-bottom: 10px solid rgba(235, 235, 235, 0.1);
  border-bottom: none;
  border-radius: 10px;
  overflow: hidden;

  @at-root .subpage .content {
    .cta-card {
      figure {
        margin: auto;
        max-width: none;
        width: calc(100% + 72px);

        img {
          min-width: auto;
          max-width: none;

        }
      }
    }
  }
}

.cta-billboard {
  min-height: 0;
  padding: 0;

  @at-root .subpage .content {
    .cta-billboard {
      figure {
        margin: auto;
        max-width: none;

        img {
          min-width: auto;
          max-width: none;
        }
      }
    }
  }

  .img {
    left: auto;
    width: auto;
    max-width: none;

    &::after {
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }
  }

  .content {
    @at-root .subpage .content {
      .cta-billboard .content {
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
      }
    }
  }
}

.cta-banner {
  background: #003d81;
  border-radius: 10px;

  .content {
    @at-root .subpage .content {
      .cta-banner .content {
        padding: 0;
      }
    }
  }
}

.cta-image {
  border-radius: 10px;
  overflow: hidden;
  border-bottom: none;

  @at-root .subpage .content {
    .cta-image {
      figure {
        margin: auto;
        max-width: none;

        img {
          min-width: auto;
          max-width: none;
        }
      }
    }
  }

  .content {
    @at-root .subpage .content {
      .cta-image .content {
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 15px;
        width: 100%;
        height: auto;
        text-align: center;
        z-index: 2;
      }
    }
  }
}
