.page-footer {
  position: absolute;
  bottom: 0;
  padding: 1.875rem 1.875rem 2.0rem;
  max-height: 225px;
  font-size: 11px;

  p {
    margin: 0;
    letter-spacing: 0;
    font-size: 11px;
    font-family: $primary-font;
    line-height: 14px;

     + p {
      margin-top: 6px;
    }
  }

  .copyright {
    color: $tundora;
    font-weight: 700;
  }

  .disclaimer {
    padding-bottom: 10px;
    color: $tundora;
    font-weight: 400;
  }

  a {
    border-bottom: 1px solid transparent;
    color: $tundora;
    text-decoration: none;
    transition: border-bottom-color 0.2s ease;

    &:hover {
      border-bottom-color: $silver;
    }
  }
}
