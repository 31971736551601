.page-main {
  padding: 30px 30px 60px;
  min-height: 100vh;

  @include respond-to(1280px) {
    padding: 0;
  }
}

.homepage {
  // background: cyan;
  @include respond-to(1280px) {
    // background: none;
  }

  .page-main {
    @include respond-to(1280px) {
      // background: lime;
    }
  }
}

.subpage {
  // background: orange;
  @include respond-to(1280px) {
  }

  .page-main {
    position: -ms-page;
    width: 100%;
    background-color: rgba($blue-charcoal, 0.85);

    @media only screen and (max-width: 1279px) {
      // height: 100vh;
      height: 100%;
    }
  }
}


// .subpage {
//   // background: orange;
//   @include respond-to(1280px) {
//   }

//   .page-main {
//     @include respond-to(320px) {
//       background-color: rgba($blue-charcoal, 0.85);
//     }
//   }
// }
