.tabs {
  position: relative;
  margin-bottom: 20px;

  &:not(.location-tabs).scroll-left:before,
  &:not(.location-tabs).scroll-right:after {
    position: absolute;
    z-index: 99;
    width: 40px;
    height: 60px;
    content: '';
  }

  &:not(.location-tabs).scroll-left:before {
    top: 0;
    left: 0;
    background: linear-gradient(to right, rgba(white, 1) 0%, rgba(white, 0) 100%);
  }

  &:not(.location-tabs).scroll-right:after {
    top: 0;
    right: 0;
    background: linear-gradient(to left, rgba(white, 1) 0%, rgba(white, 0) 100%);
  }

  .tab-con {
    position: relative;
    padding: 0;
    margin-bottom: 2px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    -webkit-overflow-scrolling: touch;
  }

  .tab-list {
    @include fontsize(14 17);

    display: none;
    padding: 0;
    margin: 0;
    font-weight: 900;
    letter-spacing: 1px;
    text-transform: uppercase;
    list-style: none;

    &[role='tablist'] {
      display: inline-block;
    }

    li {
      display: inline-block;
      padding: 0;
      margin: 0 3px 0 0;
      white-space: nowrap;

      &:last-child {
        margin: 0;
      }

      a {
        position: relative;
        display: block;
        padding: 12px 19px;
        color: black;
        text-align: center;
        text-decoration: none;
        background: gray;

        &:hover,
        &[role='tab']:focus {
          color: white;
          background: black;
          outline: 0;
        }

        &[aria-selected] {
          padding: 12px 19px;
          color: black;
          background: gray;
          border: 0;

          &:hover,
          &[role='tab']:focus {
            color: black;
            background: gray;
          }
        }
      }
    }
  }

  [role='tabpanel'] {
    &[aria-hidden='true'] {
      display: none;
    }

    .tab-title {
      display: none;
    }
  }
}

.tab-content[data-arx-type='layer'] {
  position: relative;
  margin-bottom: 15PX;
  background: repeating-linear-gradient(to right,rgba(0,88,251,0.04),rgba(0,88,251,0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)),transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)),transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0,88,251,0.2);

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2PX;
    font-size: 12PX;
    font-weight: bold;
    text-transform: uppercase;
    background: rgba(0,88,251,0.04);
    border: 1px dashed rgba(0,88,251,0.2);
    border-top: 0;
    border-right: 0;
    content: 'Tab';
  }
}
