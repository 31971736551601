// PX TO REM FUNCTION | USAGE @include rem-calc(10px 10px 10px 10px);
@use "sass:math";

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@function rem-calc($values, $base: $base-font-size) {
  $rem-values: ();
  $count: length($values);
  // If no base is defined, defer to the global font size
  @if $base==null {
    $base: 100%;
  }
  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base)=='%' {
    $base: math.div($base, 100%) * 16px;
  }
  @if $count==1 {
    @return -ws-to-rem($values, $base);
  }
  @for $i from 1 through $count {
    $rem-values: append($rem-values, -ws-to-rem(nth($values, $i), $base));
  }
  @return $rem-values;
}

@function -ws-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) !='number' {
    @warn inspect($value)+' was passed to rem-calc(), which is not a number.';
    @return $value;
  }
  // Calculate rem if units for $value is not rem
  @if unit($value) !='rem' {
    $value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
  }
  // Turn 0rem into 0
  @if $value==0rem {
    $value: 0;
  }
  @return $value;
}

// BASE TRANSITION MIXIN | USAGE @include transition(all, 0.2s, ease);
@mixin transition($property: all, $duration: 0.2s, $ease: ease) {
  transition: $property $duration $ease;
}

// CONVERT PX FONT AND LINE HEIGHTS TO REMS | USAGE @include font-size(12, 16);
@mixin font-size($sizeValue: 1, $line: $sizeValue * 1.5) {
  font-size: math.div($sizeValue, 16) + rem;
  line-height: math.div($line, 16) + rem;
}

// PLACEHOLDERS | USAGE @include placeholder {}
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-moz-placeholder {
    @content
  }
  &:-ms-input-placeholder {
    @content
  }
}

// PX TO PERCENTAGE MIXIN
$canvas-width: 768;
@function perc($pixels, $context: $canvas-width) {
  @return #{math.div($pixels, $canvas-width)*100%};
}

// FONT VIEWPORT WIDTH MIXIN
@function get-vw($value) {
  $vw: nth($value, 1);
  $fs: nth($value, 2);
  $vw_value: ($vw * 0.01) * 1;
  @return math.div($fs, $vw_value)+vw;
}

// PX TO REM WITHOUT COMMA | USAGE @include fontsize{12px} OR @include fontsize{12px 16px}
@mixin fontsize($size) {
  $fontsize: nth($size, 1);
  $lineheight: $fontsize;
  @if length($size)>1 {
    $lineheight: nth($size, 2);
  }
  @else {
    $lineheight: nth($size, 1) * 1.5;
  }
  font-size: math.div($fontsize, 16)+rem;
  line-height: math.div($lineheight, 16)+rem;
}

// Old media queries

@mixin respond-to($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}

// Responsiveness happens here.
// @include respond(min, 320px) { CONTENT }
// @include respond(max, lg: 640px) { CONTENT }
// @include respond(minmax, 320px, 640px) { CONTENT }

@mixin respond($size, $sm: "", $lg: "") {
  @if $size == "min" {
    @media only screen and (min-width: $min) {
      @content;
    }
  }

  @if $size == "max" {
    @media only screen and (max-width: $lg) {
      @content;
    }
  }

  @if $size == "minmax" {
    @media only screen and (min-width: $sm) and (max-width: $lg) {
      @content;
    }
  }
}

// MEDIA QUERY SHORTHAND
@mixin mq($query) {
  @if length($query)==1 {
    $query1: nth($query, 1);
    @if $query1=='portrait' {
      @media screen and (orientation: portrait) {
        @content
      }
    }
    @else if $query1=='landscape' {
      @media screen and (orientation: landscape) {
        @content
      }
    }
    @else {
      @media screen and (min-width: $query1 + px) {
        @content
      }
    }
  }
  @else if length($query)==2 {
    $query1: nth($query, 1);
    $query2: nth($query, 2);
    @if $query2=='portrait' {
      @media screen and (min-width: $query1 + px) and (orientation: portrait) {
        @content
      }
    }
    @else if $query2=='landscape' {
      @media screen and (min-width: $query1 + px) and (orientation: landscape) {
        @content
      }
    }
    @else {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) {
        @content
      }
    }
  }
  @else if length($query)==3 {
    $query1: nth($query, 1);
    $query2: nth($query, 2);
    $query3: nth($query, 3);
    @if $query3=='portrait' {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) and (orientation: portrait) {
        @content
      }
    }
    @else if $query3=='landscape' {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 - 1 + px) and (orientation: landscape) {
        @content
      }
    }
    @else {
      @media screen and (min-width: $query1 + px) and (max-width: $query2 + px) {
        @content
      }
    }
  }
}

// DEFAULT MEDIA QUERIES
@mixin small-only {
  @media (max-width: #{$medium - 1px}) {
    @content;
  }
}

@mixin small-only {
  @media (min-width: 0) and (max-width: #{$medium - 1px}) {
    @content;
  }
}

@mixin medium-up {
  @media (min-width: #{$medium}) {
    @content;
  }
}

@mixin medium-only {
  @media (min-width: #{$medium}) and (max-width: #{$large - 1px}) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: #{$large}) {
    @content;
  }
}

@mixin large-only {
  @media (min-width: #{$large}) and (max-width: #{$xlarge - 1px}) {
    @content;
  }
}

@mixin xlarge-up {
  @media (min-width: #{$xlarge}) {
    @content;
  }
}

@mixin xlarge-only {
  @media (min-width: #{$xlarge}) and (max-width: #{$xxlarge - 1px}) {
    @content;
  }
}

@mixin xxlarge-up {
  @media (min-width: #{$xxlarge}) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( min--moz-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2/1), only screen and ( min-device-pixel-ratio: 2), only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx) {
    @content;
  }
}



@mixin offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

@mixin sr-only {
  position: absolute;
  width: 1px ;
  height: 1px ;
  padding: 0 ;
  margin: -1px ;
  overflow: hidden ;
  clip: rect(0,0,0,0) ;
  border: 0 ;
  color: $black;
  background: $white;
}

@mixin element-invisible {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}