.content {
	fieldset {
		padding: 0;
		border: 0;

		> div {
			padding-bottom: 15px;
		}

		h2 {
			padding-bottom: 10px;
		}
	}

	.form-field {
		margin-bottom: 20px;
	}

	input[type='text'],
	textarea {
		display: block;
		margin-top: 5px;
		padding: 9px;
		width: 100%;
		border: none;
		background: rgba(250, 250, 250, 0.2);
		color: #bfbfbf;
		font-weight: 300;
		font-size: 18px;

		&:focus {
			outline: 1px solid #5798d9;
			background: transparent;
		}

		&::-webkit-input-placeholder {
			color: #bfbfbf;
		}

		&::-moz-placeholder {
			color: #bfbfbf;
		}

		&:-moz-placeholder {
			color: #bfbfbf;
		}

		&:-ms-input-placeholder {
			color: #bfbfbf;
		}
	}

	textarea {
		margin-top: 10px;
		padding: 14px;
		font-style: normal;
		font-size: 16px;

		&:focus {
			outline-color: #5798d9;
			background: transparent;
		}
	}

	label {
		padding-bottom: 5px;
		color: #ffffff;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 500;
		font-style: normal;
		font-size: 18px;
		font-family: $secondary-font;
	}

	select {
		margin-top: 5px;
		padding: 9px;
		width: 100%;
		border: none;
		background: rgba(250, 250, 250, 0.2);
		color: #bfbfbf;
		font-weight: 300;
		font-style: italic;
		font-size: 16px;

		&:focus {
			outline-color: #5798d9;
			background: transparent;
		}
	}

	button,
	html input[type='button'],
	input[type='reset'],
	input[type='submit'] {
		padding: 8px 40px;
		border: none;
		background: #ffd201;
		color: #403400;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 14px;
		font-family: $primary-font;
		cursor: pointer;
		-webkit-appearance: button;

		&:hover {
			background: #5798d9;
			color: #1a2d40;
		}
	}

	.hide {
		display: none;
	}

	.col-half {
		width: 100%;

		@include respond-to(640px) {
			float: none;
			margin-right: 0;
			width: 100%;
		}

		@include respond-to(1024px) {
			float: left;
			padding-right: 24px;
			width: 50%;
		}

		@include respond-to(1280px) {
			float: left;
			padding-right: 24px;
			width: 50%;
		}
	}

	.col-full {
		width: 100%;

		@include respond-to(1280px) {
			float: left;
			padding-right: 24px !important;
			width: calc((100% - 24px) / 1);
		}

		@include respond-to(1024px) {
			float: none;
			padding-right: 48px;
		}

		@include respond-to(640px) {
			float: left;
		}
	}

	.col-last {
		clear: right;
		margin-right: 0;
		padding-right: 0;
	}

	.col-half,
	.col-full {
		position: relative;

		&.required {
			label {
				position: relative;

				&:before {
					content: '*';
					color: red;
					position: absolute;
					right: -10px;
					top: -6px;
					font-size: 20px;
				}
			}
		}

		.error-msg {
			position: absolute;
			right: 0;
			bottom: -13px;
			color: white;
			font-size: 14px;

			@include respond-to(1024px) {
				right: 33px;
				bottom: -13px;
			}
		}
	}

	.pnl-textarea {
		margin-top: 9px;
		padding-bottom: 0;

		label {
			position: relative;
			top: 0;
			bottom: auto;
			margin-bottom: 9px;
		}

		.error-msg {
			top: 0;
			bottom: auto;
		}
	}

	.validation-summary {
		margin-bottom: 28px;

		ul {
			margin: 18px 0;
			list-style: none;
		}
	}
}

.form-builder-submit-controls {
	margin-top: 30px;
}

.g-recaptcha {
	margin-bottom: 30px;
}
