// reset DNN's built-in values for headings. ugh.
h1, h2, h3, h4, h5, h6 {
  letter-spacing:0;
  font-size:inherit;
  line-height:inherit;
  color:inherit;
}

// reset .Normal that DNN applies to EVERYTHING.
.Normal {
  line-height:inherit;
  font-size:inherit;
  color:inherit;
  margin:inherit;
  padding:inherit;
  font-weight:inherit;
}


// DNN's admin bar can't handle border-box sizing. Figures. Tell it to use the crappy old content-box model.
#ControlBar_ControlPanel, #ControlBar_ControlPanel * {
  box-sizing:content-box;
  -moz-box-sizing: content-box;
}

// Fix Controlbar's horrible ui.
#ControlBar_ControlPanel {
  height: 53px;
  // background: cyan;
}

#ControlBar {
  height: 44px;
  padding-top: 9px;
  border-top:none;
  border-bottom:none;
}

#ControlBar .ServiceIcon {
  height:38px;
}

#ControlBar .ControlContainer {
  height:43px;
  // background: lime;
}

#ControlBar #ControlBar_CustomMenuItems,
#ControlBar #ControlActionMenu,
#ControlBar #ControlEditPageMenu {
  margin-top:0;
}

// Fix positioning issue with edit menu checkboxes
#ControlEditPageMenu li.controlBar_BlueEditPageBtn {
  overflow:visible;
}

#ControlEditPageMenu > li ul li {
  overflow:hidden;
}

#ControlEditPageMenu > li ul li input[type='checkbox'] {
  float:left;
  margin:3px 4px 0 0;
}

#ControlEditPageMenu > li ul li label {
  float:left;
  cursor:pointer;
}
// End checkbox positioning fix
