﻿.subpage .content {
	.hidden {
		display: none !important;
	}

	hr {
		height: 3px;
		background-color: rgba(255, 255, 255, .2);
		width: 100%;
		margin: 20px 0;
		border: 0;
	}

	.search-container {
		display: flex;
		margin-bottom: 20px;
		flex-wrap: wrap;
		position: relative;

		@media all and (min-width: $venti) {
			flex-wrap: nowrap;
		}

		&__textfield {
			margin-right: 2px;
			border-radius: 5px 0 0 5px;
			line-height: 40px;
			padding: 0 20px;
			width: calc(100% - 45px);
			order: 1;
			background: #404e58;

			@media all and (min-width: $venti) {
				order: auto;
				line-height: 70px;
				width: 100%;
				background: rgba(250, 250, 250, .2);
			}

			&::placeholder {
				color: #fff;
			}
		}

		&__dropdownlist {
			margin-right: 2px;
			color: #fff;
			font-style: normal;
			appearance: none;
			background-image: url('/themes/DYS/assets/src/svg/caret-down-solid.svg');
			background-repeat: no-repeat;
			background-size: 14px;
			order: 3;
			background-color: transparent;
			margin-top: 20px;
			padding: 0;
			background-position: right center;

			@media all and (min-width: $venti) {
				order: 2;
				background-color: rgba(250, 250, 250, .2);
				margin-top: 5px;
				width: 350px;
				background-position: calc(100% - 20px) center;
				padding: 0 20px;
			}


			option {
				color: #222;
			}
		}

		&__button {
			width: 122px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			border: 0;
			margin-top: 5px;
			border-radius: 0 5px 5px 0;
			transition: .33s all ease-in-out;
			order: 2;
			width: 45px;
			margin-left: -2px;
			background: #4f6574;
			font-size: 18px;


			@media all and (min-width: $venti) {
				order: 3;
				font-size: 24px;
				background: rgba(255, 255, 255, .2);
				margin-left: 0;
				width: 85px;
			}

			&--clear {
				background: transparent;
				border: none;
				color: #fff;
				display: block;
				margin: 0 0 0 auto;
			}
		}
	}

	.item__card {
		width: 100%;
		background: #fff;
		display: flex;
		justify-content: space-between;
		padding: 20px 0 20px 30px;
		border-radius: 10px;
		margin-bottom: 15px;
		padding: 10px 0 10px 20px;
		overflow: hidden;
		position: relative;
		flex-direction: column;
		align-items: flex-start;

		@media all and (min-width: $venti) {
			flex-direction: row;
			align-items: center;
		}

		&--title {
			font-size: 18px;
			color: #cdda53;
			text-transform: capitalize;
			margin: 0;
			color: #003d81;
			font-size: 18px;
			font-weight: bold;
		}

		&--partNumber {
			color: #404040;
		}

		&--partHashtag {
			color: #003d81;
			font-size: 21px;
			font-weight: normal;
			line-height: 21px;
			vertical-align: -1px;
			margin-right: 2px;
		}

		&--category {
			display: inline-block;
			font-size: 14px;
			color: #262626;
			font-weight: bold;
		}

		&--actions {
			height: auto;

			@media all and (min-width: $venti) {
				display: flex;
			}
		}

		&--information {
			@media all and (max-width: 1023px) {
				line-height: 1.5rem;
				margin-bottom: 7px;
			}
		}

		&--price {
			width: 112px;
			font-size: 24px;
			color: #003d81;
			font-weight: bold;
			background: #e2ebf3;
			text-align: center;
			border-radius: 5px;
			line-height: 34px;
			padding: 0 10px;

			@media all and (min-width: $venti) {
				line-height: 60px;
				padding: 0;
			}
		}

		&--link {
			display: block;
			margin: -11px 0 -11px 10px;
			font-size: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #003d81;
			position: absolute;
			top: 10px;
			left: -10px;
			width: 100%;
			height: 100%;
			background: transparent;

			@media all and (min-width: $venti) {
				color: #fff;
				position: static;
				width: 70px;
				height: auto;
				background: #003d81;
			}
		}

		.fa.fa-arrow-right {
			@media all and (max-width: 1279px) {
				position: absolute;
				bottom: 10px;
				right: 10px;
			}
		}
	}

	.partDetail__title {
		@media all and (min-width: $venti) {
			margin-bottom: 50px;
			padding-bottom: 40px;
			border-bottom: 5px solid rgba(255, 255, 255, .2);
		}
	}

	.partDetail__price {
		position: relative;
		background: #fff;
		padding: 0 25px 30px;
		border-radius: 0 0 5px 5px;

		@media all and (min-width: $venti) {
			background: rgba(255, 255, 255, .8);
			padding: 35px 30px 40px;

			&:before {
				content: '';
				width: calc(100% - 60px);
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				position: absolute;
				height: 2px;
				background-color: #a7aeb4;
			}
		}
	}

	.partInfo {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		flex-direction: column;
		background: #fff;
		border-radius: 5px 5px 0 0;
		padding: 30px 25px 0;
		margin-bottom: 0;

		@media all and (min-width: $venti) {
			flex-direction: row;
			background: rgba(255, 255, 255, .8);
			justify-content: flex-start;
			padding: 40px 30px;
		}

		&__item {
			min-width: 33%;
		}

		&__title {
			font-size: 24px;
			font-weight: bold;
			text-transform: capitalize;
			color: #002c5a;
			margin-top: 0;
		}

		&__details {
			font-size: 24px;
			margin-top: 10px;
			color: #404040;
			margin-bottom: 0;

			&--large {
				font-size: 40px;
				font-weight: bold;
				margin-top: 15px;
				margin-bottom: 0;
				color: #404040;
			}
		}
	}

	.cta {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		background: rgba(0, 61, 129, .75);
		align-items: center;
		padding: 0 60px;
		height: 140px;
		border-radius: 5px;
		margin-top: 70px;
		margin-bottom: 40px;

		@media all and (min-width: $venti) {
			justify-content: space-between;
			text-align: left;
			flex-direction: row;
		}


		&__title {
			margin: 0;
			font-size: 36px;
			font-weight: bold;
		}

		&__link {
			font-size: 32px;
			font-weight: bold;
			white-space: nowrap;
			margin-top: 9px;

			@media all and (min-width: $venti) {
				margin-top: 0;
			}
		}
	}

	.returnLink {
		font-size: 34px;
		text-transform: uppercase;
		font-weight: normal;
		font-family: "$secondary-font", sans-serif;
		letter-spacing: .005rem;

		&__icon {
			vertical-align: middle;
		}
	}

	.inventory-pager {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: 50px auto 20px;
		position: relative;
		align-items: baseline;

		@media all and (min-width: $venti) {
			text-align: center;
			margin: 20px auto;
		}

		&__page {
			padding: 10px 12px;
			background: #fff;
			color: #003d81;
			border-radius: 5px;
			font-weight: bold;
			font-family: "nimbus-sans", sans-serif;
			text-align: center;
			line-height: 1rem;
			display: block;
			margin-bottom: 10px;

			&--current {
				color: #000;
			}
		}

		&__pager-nav {
			position: absolute;
			top: -40px;
			width: 48%;
			background: rgba(255, 255, 255, .2);
			padding: 5px 10px;
			border-radius: 5px;

			@media all and (min-width: $venti) {
				position: relative;
				top: 0;
				width: auto;
			}

			&::before {
				position: absolute;
				width: 20px;
				height: 20px;
				background: transparent;
				top: 50%;
				transform: translateY(-50%);
				font-family: 'FontAwesome';
				line-height: 21px;
			}

			&:first-child {
				padding-left: 30px;
				left: 0;

				&::before {
					content: '\f060';
					left: 5px;
				}
			}

			&:last-child {
				right: 0;
				padding-right: 30px;

				&::before {
					content: '\f061';
					right: 5px;
				}
			}
		}
	}
}
