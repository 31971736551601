table.responsive-table {
  width: 100%;

  @include medium-up {
    border-radius: 0;
    border-collapse: inherit;
    border-spacing: 0;
    overflow: hidden;
    border-width: 2px;
  }

  .cell-heading {
    display: block;
    width: 100%;
    font-weight: bold;
    margin-bottom: 10px;

    @include medium-up {
      display: none;
    }
  }

  .cell-content {
    display: block;
    width: 100%;
  }

  thead {
    background-color: $table-head-background;

    tr {
      display: none;

      @include medium-up {
        display: table-row;
      }

      th {
        font-family: $primary-font;        
        color: $table-head-text;
        text-transform: none;
        @include fontsize(18 30);
        padding: 6px 12px;        
        text-align: left;

        @include medium-up {
          background: $table-head-background;
          border-right: 2px solid $table-border;

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:last-child {
        border-bottom: 0;
      }

      &:nth-child(odd) {
        background: rgba(235, 235, 235, 0.3);
      }

      &:nth-child(even) {
        background: rgba(235, 235, 235, 0.2);
      }

      td {
        display: block;
        clear: left;
        font-family: $primary-font;
        padding: 6px 12px;
        border-bottom: 2px solid $table-border;
        @include fontsize(16 30);

        @include medium-up {
          display: table-cell;
          border-bottom: none;
          border-top: 2px solid $table-border;
          border-right: 2px solid $table-border;

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}