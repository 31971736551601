html {
  box-sizing: border-box;
  min-width: 320px;
  width: 100%;
  background: url('../images/dys-interior.jpg') no-repeat top fixed;
  background-color: $blue-charcoal;
  background-attachment: fixed;
  background-size: cover;

  @include respond-to(1280px) {
    background-image: url('../images/dys-interior.jpg');
    background-attachment: fixed;
    background-position: 30% top;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

body {
  min-width: 320px;
  width: 100%;
  color: $tundora2;
  font: 14px Verdana, sans-serif;

  &:after {
    display: none;

    @include respond-to(1280px) {
      position: absolute;
      bottom: 11px;
      left: 0;
      z-index: -1;
      display: block;
      width: calc(100% - 426px);
      height: 207px;
      content: '';
      opacity: 0.75;
      background-image: linear-gradient(to bottom, rgba(8, 8, 8, 0) 0%, rgba(8, 8, 8, 1) 100%);
      // @include linear-gradient(to bottom, rgba(8, 8, 8, 0) 0%, rgba(8, 8, 8, 1) 100%);
    }
  }

  &:after {
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    right: 0;
    left: 0;
    z-index: -1; /* needed to keep in the background */
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    // background-color: rgba(0, 13, 26, 0.85);
    // background: url('../images/dys-interior.jpg') center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    content: '';
  }
}

body.subpage {
  &:after {
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    right: 0;
    left: 0;
    z-index: -1; /* needed to keep in the background */
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    background: url('../images/dys-interior.jpg') center center;
    background-color: rgba(0, 13, 26, 0.85);
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
    content: '';

    // background-blend-mode: darken;
  }
}

form {
  min-width: 320px;
  width: 100%;
  height: 100vh;
}

.page {
  min-width: 320px;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  background: #ffffff;
  color: #000000;
}

.dealers .carousel .item {
  display: inline-block;
  width: 175px;

  img {
    margin: 0 auto;
    width: auto;
  }
}

.body-container {
  position: relative;

  // @at-root body.has-alert {
  //   .body-container {
  //     margin-top: 60px;
  //   }
  // }
}
