a.skipcontent {
  position: absolute;
  top: -40px;
  left: 20px;
  z-index: 12;
  padding: 6px 8px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: transparent;
  color: white;
  -webkit-transition: top 1s ease-out, background 1s linear;
          transition: top 1s ease-out, background 1s linear;

  &:focus {
    position: absolute;
    top: 0;
    left: 20px;
    outline: 0;
    background: #bf1722;
    -webkit-transition: top 0.1s ease-in, background 0.5s linear;
            transition: top 0.1s ease-in, background 0.5s linear;

    @media only screen and (max-width: 1023px) {
      position: relative;
    }
  }
}
