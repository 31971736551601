.nav-global {
  display: block;
  font-family: $primary-font;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    position: relative;
    float: left;
    padding: 0 15px;
    margin: 0;

    &:before {
      position: absolute;
      top: 4px;
      left: 0;
      display: block;
      width: 1px;
      height: 10px;
      background: $silver;
      content: '';
    }

    &:first-child {
      padding-left: 0;

      &:before {
        display: none;
      }
    }

    &:last-child {
      padding-right: 0;
    }
  }

  a {
    position: relative;
    display: block;
    font-size: 18px;
    color: inherit;
    text-decoration: none;
  }

  .fa-facebook {
    color: $facebook-blue;
  }
}

.page-nav {

  .nav-global {
    padding: 21px 25px 21px 28px;
    border-bottom: 1px solid $silver;

    li {
      padding-right: 10px;
      padding-left: 10px;
      font-size: 13px;
      line-height: 16px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        position: absolute;
        right: 25px;
        width: 35px;
        padding-right: 0;
        text-align: right;

        &:before {
          top: -11px;
          height: 40px;
        }
      }
    }

    a {
      font-size: 13px;
      line-height: 16px;
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 0.2s ease, color 0.2s ease;

      &:hover {
        border-bottom-color: $silver;
      }

      &.icon {
        font-size: 16px;

        &:hover,
        &:focus {
          color: $hoki;
          border-bottom-color: transparent;
        }
      }
    }

    .fa-facebook {

      &:hover,
      &:focus {
        color: $hoki;
      }
    }
  }
}


.touch-menu {

  .nav-global {
    float: right;
    padding: 3px 0px 0px;
  }
}
