﻿.dock-map.subpage,
.new-dock-map.subpage {
  h1{
    display: none;
  }

  .content{
    padding-right: 60px;
  }

  .page-nav{
    right: -100%;
  }
}

#Dock_Status {
	.dock,
	.fuel {
		cursor: pointer;

		.dock-info-bg,
		.fuel-info-bg {
			transition: fill 0.33s ease-in-out;
			fill: #fff;
		}

		.hover-dock-details {
			opacity: 0;
			transition: all .33s ease-in-out;
			width: 200px;
			height: auto;
			background: #000;
			padding: 10px 10px 20px;
			color: #fff;
		}

		&:hover,
		&:focus {
			.dock-info-bg,
			.fuel-info-bg {
				fill: #cdda53;
			}
		}
	}

	.available {
		.dock-occupied,
		.fuel-occupied {
			opacity: 0;
		}

		.hover-dock-details {
			opacity: 0;
		}
	}
}

.hover-dock-details {
	opacity: 0;
	transition: all .33s ease-in-out;
	width: 200px;
	height: auto;
	background: #000;
	padding: 10px 10px 20px;
	color: #fff;
	pointer-events: none;

	&.hover {
		opacity: 1;
		pointer-events: all;
	}
}

//SVG Display Styles
svg {
	background: transparent url('/themes/DYS/assets/dist/images/DYS-DockMap-Background.png') no-repeat center;
	background-size: cover;
	border-radius: 20px;
  max-height: calc(100vh - 240px);
  margin: 0 auto;
  display: block;
}

.cls-1 {
	isolation: isolate;
}

.cls-3 {
	fill: #054166;
	mix-blend-mode: overlay;
}

.cls-16,
.cls-3 {
	opacity: 0.5;
}

.cls-4 {
	mix-blend-mode: multiply;
}

.cls-5,
.cls-7 {
	opacity: 0.2;
}

.cls-6 {
	fill: #e6eced;
}

.cls-8 {
	opacity: 0.8;
	fill: #2d3138;
}

.cls-9 {
	fill: #a7b3b6;
}

.cls-10 {
	fill: #97a3a5;
}

.dock-number,
.fuel-number {
	font-family: $secondary-font;
	font-size: 40px;
	font-weight: 800;
}

.cls-12,
.cls-14 {
	fill: #262626;
}

.dock-available,
.fuel-available {
	fill: #cdda53;
}

.cls-15 {
	fill: #00b2ea;
}

.cls-16 {
	fill: #006ac1;
}

.st13 {
	fill: #262626;
}

.st19 {
	fill: #00B2EA;
}

.st20{
	fill: #006AC1;
}

.subpage .content .modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	background: rgba(2, 25, 40, .75);
	overflow: hidden;
	opacity: 0;
	transition: opacity .33s ease-in-out;

	&.active {
		width: 100vw;
		height: 100vh;
		opacity: 1;
		visibility: visible;
	}

	* {
		color: #000;
	}

	h2 {
		font-family: $secondary-font;
		font-weight: bold;
	}

	label {
		font-family: "nimbus-sans", sans-serif;
		font-weight: bold;
		padding: 0;
		font-size: 16px;
		text-transform: capitalize;
		margin-bottom: -10px;
	}
}

.modal-edit-dock,
.modal-edit-fuel {
	display: block;
	position: absolute;
	top: 150%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	padding: 10px 40px 30px;
	transition: top .33s ease-in-out;
	tranistion-delay: .33s;
	border-radius: 10px;
	width: 500px;
	max-width: 100%;

	.modal-close {
		width: 20px;
		height: 20px;
		position: absolute;
		top: -25px;
		right: -25px;
		cursor: pointer;

		&:hover,
		&:focus {
			&:before, &:after {
				background: rgba(255, 255, 255, 1);
			}
		}

		&:before,
		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			background: rgba(255, 255, 255, .75);
			border-radius: 2px;
			width: 100%;
			height: 3px;
			content: '';
			transition: all .33s ease-in-out;
		}

		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	.modal-overlay.active & {
		top: 50%;
		display: block;
    visibility: visible;
    opacity: 1;
	}

	input[type="text"],
	input[type="date"] {
		border: 1px solid #999;
		padding: 5px;
		margin-top: 0;
	}

	.edit-dock-availability,
	.edit-fuel-availability {
		position: absolute;
		top: 10px;
		display: flex;
		right: 35px;
		flex-direction: column-reverse;
		align-items: center;

		label {
			padding: 0;
			font-size: 16px;
			text-transform: capitalize;
			margin-bottom: -10px;
		}
	}

	input[type='checkbox'] {
		position: relative;
		border: 0;
		outline: 0;
		cursor: pointer;
		margin: 10px;
		appearance: none;
		-webkit-tap-highlight-color: transparent;

		&:before {
			content: '';
			width: 32px;
			height: 32px;
			display: block;
			position: absolute;
			left: 32px;
			top: -3px;
			border-radius: 50%;
			background: rgb(255, 255, 255);
			box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
			transition: ease .3s;
		}

		&:after {
			content: '';
			width: 60px;
			height: 28px;
			display: inline-block;
			background: #262626;
			border-radius: 18px;
			clear: both;
			transition: ease .3s;
		}

		&:checked {
			&:before {
				left: 0;
				box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);
			}

			&:after {
				background: #cdda53;
			}
		}
	}
}
