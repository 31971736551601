.homepage {
  .content {
    h1 {
      color: $white;
      text-transform: uppercase;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      letter-spacing: 1px;
      font-weight: 700;
      font-size: 36px;
      font-family: $secondary-font;

      @include respond-to(1280px) {
        font-size: 72px;
        line-height: 57px;
      }
    }
  }
}

.subpage {
  .content {
    h1 {
      margin-top: 0;
      margin-bottom: 16px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 40px;
      font-family: $secondary-font;
      line-height: 40px;
    }

    h2 {
      margin-bottom: 4px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 24px;
      font-family: $secondary-font;
    }

    h2.list {
      margin-bottom: 4px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 20px;
      font-family: $secondary-font;
    }

    h3 {
      margin-bottom: 0;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 20px;
      font-family: $secondary-font;
    }

    h4 {
      margin-bottom: 0;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 18px;
      font-family: $secondary-font;
    }

    h5 {
      color: $white;
    }

    h6 {
      color: $white;
    }

    p {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }

    blockquote {
      margin-left: 0;
      padding-left: 55px;
      border-left: 3px solid #2d3c56;
      color: #ffffff;
      quotes: '“' '”' '‘' '’';
      font-weight: 300;
      font-style: italic;
      font-size: 24px;
      font-family: nimbus-sans, sans-serif;

      &:before {
        margin-left: -10px;
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }

    ul {
      margin-top: 7px;
      margin-left: 0;
      width: 75%;
      border-top: 3px solid #2d3c56;
      list-style: none;

      @media only screen and (max-width: 1280px) {
        width: 100%;
      }

      li {
        margin-left: -40px;
        padding: 7px 0;
        border-bottom: 1px solid #333d47;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        font-family: $primary-font;
        line-height: 25px;

        &:last-child {
          border-bottom: none;
        }

        span {
          color: #ffffff;
          font-weight: 600;
        }
      }
    }

    ol {
      margin-top: 7px;
      margin-left: 0;
      padding-left: 0;
      width: 75%;
      border-top: 3px solid #2d3c56;
      counter-reset: li;
      font-weight: 500;
      font-style: normal;
      font-size: 15px;
      font-family: $primary-font;

      @media only screen and (max-width: 1280px) {
        width: 100%;
      }

      li {
        position: relative;
        margin: 0;
        padding: 7px 20px;
        border-bottom: 1px solid #333d47;
        list-style: none;
        line-height: 25px;

        &:before {
          position: absolute;
          top: -1px;
          left: -10px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          margin-right: 8px;
          padding: 7px;
          width: 2em;
          color: #ffffff;
          content: counter(li);
          counter-increment: li;
          text-align: center;
          font-weight: 700;
          font-style: normal;
          font-size: 15px;
          font-family: nimbus-sans, sans-serif;
        }

        &:last-child {
          border-bottom: none;
        }

        span {
          color: #ffffff;
          font-weight: 600;
        }
      }
    }

    figure {
      margin: 1em 20px;
      max-width: 250px;
      width: 100%;

      img {
        min-width: 250px;
        max-width: 250px;
        width: 100%;
      }

      figcaption {
        position: relative;
        margin-bottom: 10px;
        padding: 0 0 10px;
        border-bottom: 3px solid #2d3c56;
        background: transparent;
        text-align: left;
        font-weight: 400;
        font-style: italic;
        font-size: 15px;
        font-family: nimbus-sans, sans-serif;
        line-height: 20px;
      }
    }
  }
}
