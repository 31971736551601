.nav-secondary {
  padding: 9px 30px 11px;
  font-family: $primary-font;
  font-size: 15px;
  font-weight: 400;
  color: $tundora;
  background: $concrete;

  .ul1 {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .li1 {
    margin: 0;
  }

  .a1 {
    display: block;
    line-height: 28px;
    color: inherit;
    text-decoration: none;
  }
}
