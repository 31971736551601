.modal {
  z-index: 3000;
}

.modal-overlay {
  z-index: 2000;
}

body.modal-open {
  position: absolute;
  z-index: 1000;
  width: 100%;
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s;

  &.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  display: none;
  width: 98vw;
  height: auto;
  max-height: calc(90vh - 40px);
  min-height: calc(90vh - 40px);
  overflow: visible;
  text-align: center;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);

  @media only screen and (min-width: $medium) {
    width: 96vw;
    height: auto;
    max-height: 90vh;
    min-height: auto;
    overflow-y: visible;
  }

  @media only screen and (min-width: $large) {
    width: 60vw;
    height: auto;
    max-width: 500px;
    max-height: 90vh;
    min-height: auto;
    overflow: visible;

    &.wide {
      width: 80vw;
      max-width: 80vw;
    }
  }

  @media only screen and (min-width: $xlarge) {
    width: 50vw;
    height: auto;
    max-width: 640px;
    max-height: 90vh;
    min-height: auto;
    overflow: visible;

    &.wide {
      width: 80vw;
      max-width: 80vw;
    }
  }

  &.show-modal {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 0.25s 0s;
  }

  .close-modal {
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    width: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    background-color: lightgray;
    border-radius: 0.25rem;

    &:hover {
      background-color: darkgray;
    }
  }

  .modal-content {
    height: 100%;
    max-height: inherit;
    padding: 31px 20px 20px;
    overflow-y: auto;
  }
}

.modal[data-arx-type='layer'] {
  position: relative;
  top: auto;
  left: auto;
  z-index: 50;
  display: block;
  width: 100%;
  height: auto;
  max-width: none;
  margin-bottom: 15PX;
  background: repeating-linear-gradient(to right,rgba(0,88,251,0.04),rgba(0,88,251,0.04) calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)),transparent calc((100% / var(--arx-grid-columns)) - var(--arx-grid-gutter)),transparent calc(100% / var(--arx-grid-columns)));
  outline: 2PX dashed rgba(0,88,251,0.2);
  opacity: 1;
  visibility: visible;
  transform: none;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    padding: 2PX;
    font-size: 12PX;
    font-weight: bold;
    text-transform: uppercase;
    background: rgba(0,88,251,0.04);
    border: 1px dashed rgba(0,88,251,0.2);
    border-top: 0;
    border-right: 0;
    content: 'Modal';
  }
}