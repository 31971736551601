// cta
.cta-card {
  padding: 0 36px 36px 36px;
  background: rgba(black, 0.35);
  border-bottom: 10px solid black;

  h2,
  h3 {
    margin-bottom: 0;
    @include font-size(18,30);
    border: 0;

    a {
      border: 0;
    }
  }

  p {
    margin: 15px 0;
    @include font-size(16,26);
  }

  figure {
    position: relative;
    left: -36px;
    width: calc(100% + 72px);
    max-width: calc(100% + 72px);

    img {
      margin: 0 auto;
      width: calc(100% + 72px);
    }
  }

  &[data-arx-type] {
    figure {
      left: auto;
      width: 100%;
    }

    img {
      margin: 0 auto;
      width: 100%;
    }
  }
}

.cta-image {
  position: relative;
  border-bottom: 10px solid black;

  .img,
  figure {
    margin: 0 auto;
    z-index: 1;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, 0.8) 100%);
      content: '';
    }

    figure {
      width: 100%;
    }

    img {
      margin: 0 auto;
      width: 100%;
    }
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding-bottom: 15px;
    width: 100%;
    height: auto;
    text-align: center;
    z-index: 2;
  }

  h2,
  h3 {
    @include font-size(20,30);
    color: white;
    border: 0;

    a {
      border: 0;
    }
  }

  p {
    @include font-size(16,26);
    color: white;
  }

  &[data-arx-type] {
    .content {
      position: relative;
    }

    h3,
    p {
      color: black;
    }
  }
}

.cta-banner {
  margin: 15px 0;
  padding: 36px 40px;
  color: white;
  background: gray url('../images/bg-header.svg') right bottom / contain no-repeat;

  p {
    @include font-size(20,30);
    color: white;
  }
}

.cta-banner-wide {
  margin: 15px 0;
  padding: 36px 40px;
  text-align: center;
  color: white;
  background: gray url('../images/bg-header.svg') right bottom / contain no-repeat;

  p {
    @include font-size(20,30);
    color: white;
  }
}

.cta-billboard {
  position: relative;
  margin: 15px 0;
  padding: 0 40px;
  min-height: 300px;
  text-align: center;
  color: white;
  background: gray;

  .img {
    position: relative;
    left: -40px;
    width: calc(100% + 80px);
    max-width: calc(100% + 80px);
    z-index: 1;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(black, 0.8);
      content: '';
    }

    figure {
      width: 100%;
    }

    img {
      margin: 0 auto;
      width: 100%;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 2;
  }

  p {
    width: 100%;
    @include font-size(20,30);
    color: white;
  }

  &[data-arx-type] {
    .img {
      margin: 0 auto;
      left: auto;
      width: 100%;
      max-width: 100%;

      &:after {
        display: none;
      }
    }

    .content {
      position: relative;
    }
  }
}