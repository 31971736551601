.page {
  @include respond-to(1280px) {
    // border-bottom: 10px solid $prussian-blue;
  }

  .page-inner {
    @include respond-to(1280px) {
      // border-bottom: 1px solid $scorpion;
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 280px;
    content: '';
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    // @include linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);

    @include respond-to(1280px) {
      width: 100%;
      height: 239px;
      opacity: 0.50;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

      // @include linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    }
  }
}

.border-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 10px;
  border-top: 1px solid #595959;
  background: #002d59;
}
